import { useReducedMotion } from "framer-motion";

export function Video({ src }: { src: string }) {
  const reducedMotion = useReducedMotion();

  return (
    <video
      autoPlay={!reducedMotion}
      muted
      loop
      playsInline
      slot="image"
      className="absolute inset-0 w-full h-full object-cover"
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}
